<script>
export default {
  name: 'DropDownNav',
  props: {
    menu: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    isTouch: false
  }),
  computed: {
    items: vm => vm.defineActionType(vm.menu)
  },
  methods: {
    /**
     * Define the correct bounded props based on the menu item action,
     * injects additional attributes when needed (e.g.: target, rel)
     */
    attachAttributes (menuItem, attrs = {}) {
      return {
        ...attrs,
        [menuItem.action]: menuItem.link,
        ...menuItem.attrs
      }
    },
    /**
     * Recusively add the action each menu item should have
     * based on the "href" or "to" key configured
     */
    defineActionType (menu) {
      return menu.map(({ href, to, items = [], ...menuItem }) => {
        const link = href || to
        const action = link
          ? typeof link === 'string' && link.startsWith('http')
            ? 'href'
            : 'to'
          : undefined
        const item = { ...menuItem, link, action }
        if (items.length) item.items = this.defineActionType(items)
        return item
      })
    },
    /**
     * Prevent user navigating to an external link
     * when touching over the parent menu item on mobile
     */
    preventClick (item, event) {
      if (this.isTouch && item.items) {
        event.preventDefault()
      }
    }
  }
}
</script>

<template>
  <div class="dropdown__nav">
    <VMenu
      v-for="(lvl1, i) in items"
      :key="`lvl1.${i}`"
      :transition="false"
      open-delay="0"
      close-delay="0"
      content-class="dropdown__nav__menu"
      open-on-hover
      :open-on-click="false"
      :open-on-focus="false"
      no-click-animation
    >
      <template #activator="{ props }">
        <VBtn
          :ripple="false"
          v-bind="attachAttributes(lvl1, props)"
          :class="[
            'text-none font-weight-medium subtitle-2',
            { 'v-btn--menu': lvl1.items }
          ]"
          rounded="xs"
          variant="text"
          @touchstart="isTouch = true"
          @click="preventClick(lvl1, $event)"
        >
          {{ lvl1.label }}
        </VBtn>
      </template>
      <VList
        v-if="lvl1.items"
        density="compact"
        class="py-0 px-0"
      >
        <VListItem
          v-for="(lvl2, j) in lvl1.items"
          :key="`lvl2.${j}`"
          v-bind="attachAttributes(lvl2)"
          class="py-0 px-0"
        >
          <VListItemTitle
            class="mx-0 my-0 py-4 px-6 font-weight-medium text-subtitle-2"
          >
            {{ lvl2.label }}
            <VChip
              v-if="lvl2.badge"
              :color="lvl2.badge.color"
              class="ml-4 float-right"
              pill
              size="x-small"
            >
              {{ lvl2.badge.label }}
            </VChip>
          </VListItemTitle>
        </VListItem>
      </VList>
    </VMenu>
  </div>
</template>

<style lang="scss">
.dropdown__nav {
  .v-btn {
    border-radius: 2px;
    transition: none;

    &.v-btn--has-bg {
      background: transparent;
    }

    .v-btn__overlay, .v-btn__underlay {
      opacity: 0 !important;
    }

    &[aria-expanded="true"],
    &:hover {
      background: rgb(var(--v-theme-on-primary));
      color: rgb(var(--v-theme-primary));
    }

    &--menu {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &::before {
      opacity: 0 !important;
      transition: none;
    }
  }

  &__menu,
  &__menu .v-list {
    border-radius: 2px !important;
    border-top-left-radius: 0 !important;
  }

  &__menu {
    .v-list-item--active {
      &::before {
        opacity: 0;
      }

      &:hover::before {
        opacity: 0.04;
      }
    }
  }
}
</style>
