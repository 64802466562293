import colors from 'vuetify/util/colors'

// Colours from any of the colors Material Design palettes can be used.
// @see https://vuetifyjs.com/en/style/colors#material-colors

import brand from './brand'
import parse from '../utils/palette'
// Brand colors are defined separately.

/**
 * Theme elements are used primarily in applications and should be semantically
 * named. Avoid using a description of the color as the name of the color, since
 * this can change and will lose it's contextual meaning. The following defaults
 * are set by the theme system if not overridden here:
 *   primary: '#1976D2'
 *   secondary: '#424242'
 *   accent: '#82B1FF'
 *   error: '#FF5252'
 *   info: '#2196F3'
 *   success: '#4CAF50'
 *   warning: '#FFC107'
 */

export const themeColors = {
  accent: brand.green,
  error: colors.red.accent2,
  primary: brand.blue,
  secondary: brand.blueLight,
  warning: colors.orange.darken1,
  info: colors.blue,
  success: colors.green,
  grey: colors.grey
}

const theme = {
  'acv-primary': brand.acvPrimary,
  'acv-secondary': brand.acvSecondary,
  dark: '#1E242D',
  favorite: '#E05358',
  light: brand.white,
  ...parse(themeColors)
}

export default theme
