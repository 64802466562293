import merge from 'merge'

import { makePalette } from '@maxsystems/material-color-tool'

export default (colors, inherited = {}) => {
  colors = merge(inherited, colors)
  return Object.keys(colors).reduce((result, key) => {
    let color = colors[key]
    if (typeof color === 'string') {
      const [lighten5, lighten4, lighten3, lighten2, lighten1, base, darken1, darken2, darken3, darken4] = makePalette(color)
      color = { base, lighten5, lighten4, lighten3, lighten2, lighten1, darken1, darken2, darken3, darken4 }
    }

    result[key] = color.base

    result[`${key}-lighten-1`] = color.lighten1
    result[`${key}-lighten-2`] = color.lighten2
    result[`${key}-lighten-3`] = color.lighten3
    result[`${key}-lighten-4`] = color.lighten4
    result[`${key}-lighten-5`] = color.lighten5

    result[`${key}-darken-1`] = color.darken1
    result[`${key}-darken-2`] = color.darken2
    result[`${key}-darken-3`] = color.darken3
    result[`${key}-darken-4`] = color.darken4
    return result
  }, {})
}
