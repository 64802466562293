<script setup>
import { useTheme } from 'vuetify'
const theme = useTheme()
</script>

<template>
  <svg
    class="popover__arrow"
    :fill="theme.current.dark ? '#1E1E1E' : 'white'"
    height="13"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0-.5h32v1c-1.835 0-3.582.754-4.805 2.072l-7.873 8.492c-1.775 1.915-4.869 1.915-6.644 0L4.805 2.572A6.554 6.554 0 000 .5v-1z"
      :stroke="theme.current.dark ? 'white' : 'black'"
      stroke-opacity="0.1"
      fill="inherit"
    />
  </svg>
</template>
