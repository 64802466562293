/**
 * Adds the given icon values to the framework icons object
 * @param {[key: string]: string} iconsToAdd An object that specifies the icon names and svg values (e.g. { iconName: 'svg' })
 */
export const injectIcons = (vuetify, iconsToAdd) => {
  const currentIcons = vuetify.icons.aliases
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    checkForDuplicateIcons(vuetify.icons.aliases, iconsToAdd)
  }
  vuetify.icons.aliases = {
    ...iconsToAdd,
    ...currentIcons
  }
}

function checkForDuplicateIcons (existingIcons, newIcons) {
  const existingIconKeys = Object.keys(existingIcons)

  Object.entries(newIcons).forEach(([key, path]) => {
    if (existingIcons[key] && existingIcons[key] !== path) {
      console.warn(`The icon '${key}' is being overwritten by an existing icon with the same name!`)
    } else {
      const existingIcon = existingIconKeys.find(iconKey => iconKey !== key && existingIcons[iconKey] === path)
      if (existingIcon) {
        console.warn(`The icon for '${key}' already exists, use '${existingIcon}' instead.`)
      }
    }
  })
}
