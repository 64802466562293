import guideRoute from './Guide/routes.js'

export default ({ name, path, meta, defaultTab } = {}) => [
  {
    name,
    path,
    meta,
    component: () => import(
    /* webpackChunkName: "photos" */
      './index.vue'
    ),
    redirect: to => `${to.path}/${defaultTab}`,
    children: [
      {
        name: name + '.feeds',
        path: 'feeds',
        meta,
        redirect: 'feeds/driveway',
        children: [
          {
            name: name + '.feeds.strategy',
            path: ':strategy(driveway)',
            meta
          }
        ]
      },
      {
        name: name + '.tab',
        path: ':tab(appraisal|inventory|deleted)',
        meta
      }
    ]
  },
  guideRoute({ name, path, meta })
]
