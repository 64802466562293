<script>
import DropDownNav from './DropDownNav.vue'
import { getFirstlookUrl, getFirstlookReportsUrl } from '~/shared/firstlook-url.js'

export default {
  name: 'MenuEnterprise',
  components: {
    DropDownNav
  },
  computed: {
    menu: vm => [
      {
        label: 'Group Dashboard',
        to: { name: 'enterprise.dashboard' }
      },
      {
        label: 'Group Inventory',
        to: { name: 'enterprise.inventory' }
      },
      {
        label: 'Group Appraisals',
        to: { name: 'enterprise.appraisals' }
      },
      {
        label: 'Group Reports',
        href: getFirstlookReportsUrl(`?dealerId=${vm.$auth.group.id}`).href,
        attrs: {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      },
      {
        label: 'Tools',
        items: [
          {
            label: 'Group Autoload Credentials',
            href: getFirstlookUrl('/merchandising/Dashboard/Group/AutoloadCredentials.aspx').href,
            attrs: {
              target: '_blank',
              rel: 'noopener noreferrer'
            }
          },
          {
            label: 'Group Transfer Log',
            to: { name: 'enterprise.transfers' }
          },
          {
            label: 'Lost Customers',
            to: { name: 'enterprise.appraisals.lost-customers' }
          }
        ]
      }
    ]
  }
}
</script>

<template>
  <DropDownNav :menu="menu" />
</template>
