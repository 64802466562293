export { getFirstlookUrl, getFirstlookReportsUrl } from '../firstlook-url.js'

/**
  Each menu `subscription` starts with the default set of menu items
  and the `deactivatedLinks` object (in combination with the `isIncluded` function)
  is used to selectively remove undesired menu items from the final menu set.
*/
const deactivatedLinks = {
  FL_MAX: new Set(),
  FIRSTLOOK: new Set([
    'inventory.merchandising',
    'reports.stopDrop',
    'reports.notOnline',
    'reports.lowActivity'
  ]),
  MAX: new Set([
    'dashboard',
    'appraisal',
    'stocking',
    'reports.all',
    'reports.water',
    'reports.loan',
    'reports.recalls',
    'reports.pricing',
    'reports.dealLog',
    'reports.management'
  ]),
  MAX_PRICING: new Set(['stocking']),
  // APPRAISAL_ONLY is an ui-only mapped subscription based on dealer isAppraisalOnly flag
  APPRAISAL_ONLY: new Set([
    'dashboard',
    'inventory',
    'inventory.merchandising',
    'appraisal.makeADeal',
    'stocking.core',
    'stocking.auctions',
    'stocking.flSearch',
    'stocking.flashLocator',
    'stocking.vehicleAnalyzer',
    'reports'
  ])
}

/**
 * Indicates if a given link menu item is included in the current subscription level
 * @param {string} link - menu item key
 * @param {Object} config - a config object
 * @param {boolean} config.isAppraisalOnly - dealer isAppraisalOnly flag
 * @param {string} config.subscription - dealer subscription
 * @returns {boolean}
 */
export function isIncluded (link, { isAppraisalOnly, subscription }) {
  return !deactivatedLinks[isAppraisalOnly ? 'APPRAISAL_ONLY' : subscription]?.has(link)
}

/**
 * Uses dealer config object to verify feature activation as configured in Pitstop.
 * @param {string} feature - feature key (e.g. ims.features.newCarPricing)
 * @param {object} config - dealer $auth config object
 * @returns {boolean}
 */
export function hasFeature (feature, config) {
  let obj
  return feature.split('.').every(key => {
    if (!obj) obj = config[key]
    else obj = obj[key]
    return Boolean(obj)
  })
}

/**
 * Returns the URL for the given path in the current environment.
 * @param {string} path - path to link to
 * @param {string} envPrefix - environment prefix (e.g. 'stage' or 'dev')
 * @returns {string} An absolute URL for client-side or a relative path for server-side
 */
export function getlilypadUrl (path, envPrefix) {
  // Allow linking within lilypad and to legacy URL on the shared SSR route
  const isClientSide = typeof window !== 'undefined'
  const lilypadBaseUrl = `https://${envPrefix ? envPrefix + '.' : ''}my.max.auto`

  return isClientSide ? path : new URL(path, lilypadBaseUrl).href
}
