import { datadogRum } from '@datadog/browser-rum'

/**
 * Datadog Browser Realtime User Monitoring
 * @see: {@link https://docs.datadoghq.com/real_user_monitoring/browser/|Datadog Browser RUM}
 */
if (isTracked()) {
  datadogRum.init({
    applicationId: '115fc3fd-312e-4145-ac16-0456cdf60143',
    clientToken: 'pub9730f86ea94be38ec53ad84a0786582e',
    site: 'datadoghq.com',
    service: 'lilypad-web-vuejs', // Used to identify the service in Datadog.
    env: getEnvironmentVariableValue(),
    version: process.env.SHA, // Used to identify the deployed version in Datadog.
    sessionReplaySampleRate: 20, // Only record some replays to reduce cost.
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [
      /https:\/\/.*\.maxapps\.io/,
      /https:\/\/.*\.firstlook\.biz/
    ]
  })
}

/**
 * Determines if we should initialize Datadog based on the runtime environment.
 * Collecting only on my.max.auto, stage.my.max.auto, and dev.my.max.auto.
 * @returns {boolean} Whether or not Datadog should be initialized in this environment
 */
function isTracked () {
  return process.env.NODE_ENV === 'production' &&
    ['production', 'stage', 'dev'].includes(process.env.DEPLOY_ENV)
}

/**
 * Generates the appropriate Datadog environment value based on `DEPLOY_ENV`.
 * @returns {string} Value to be used as `DD_ENV` for Datadog
 */
function getEnvironmentVariableValue () {
  const mapping = {
    production: 'production',
    stage: 'staging',
    dev: 'development'
  }
  return `max-${mapping[process.env.DEPLOY_ENV]}`
}
