<script>
import DropDownNav from './DropDownNav.vue'
import menu from '../shared/menu/index.js'

export default {
  name: 'MenuApp',
  components: {
    DropDownNav
  },
  props: {
    env: {
      type: String,
      default: process.env.DEPLOY_ENV || ''
    }
  },
  computed: {
    menu,
    subscription: ({ $auth: { dealer } }) => dealer?.subscription,
    config: ({ $auth: { dealer } }) => dealer?.config,
    hasGroupAccess: ({ $auth: { hasGroupAccess } }) => hasGroupAccess,
    dealerGroupId: ({ $auth: { dealer } }) => dealer.group?.id,
    buid: ({ $auth: { dealerId } }) => dealerId,
    isAppraisalOnly: ({ $auth }) => $auth.user.dealer.node.isAppraisalOnly
  }
}
</script>

<template>
  <DropDownNav :menu="menu" />
</template>
