<script>
import { computed } from 'vue'

import FeatureDialog from './FeaturePreview/index.js'
import NetworkCheck from './NetworkCheck.vue'
import { useAuth } from '~/plugins/auth.js'
import { ProgrammaticDialog } from '~/ui/elements'

const NetworkDialog = ProgrammaticDialog(NetworkCheck)

export default {
  name: 'MenuUser',
  setup () {
    const auth = useAuth()

    const links = computed(() => [
      { title: 'Feature preview', role: 'ADMINISTRATOR', props: { onClick: () => FeatureDialog.open() } },
      { title: 'Network check', props: { onClick: () => NetworkDialog.open() } },
      { title: 'My profile', props: { href: process.env.FL_HOST + '/IMT/EditMemberProfileAction.go', target: '_blank' } },
      { title: 'Settings', props: { to: '/settings' } },
      { title: 'Logout', props: { onClick: auth.logout } }
    ].filter(link => !link.role || link.role === auth.user?.role))

    return {
      auth,
      links
    }
  }
}
</script>

<template>
  <VMenu
    location="bottom"
    content-class="v-toolbar__nav--user__menu"
    :offset="[-44, 0]"
    transition="fade-transition"
  >
    <template #activator="{ props }">
      <VBtn
        :ripple="false"
        class="v-toolbar__nav--user"
        color="#FFF"
        icon
        v-bind="props"
      >
        <VIcon>$account</VIcon>
      </VBtn>
    </template>
    <VList
      class="py-0 px-0"
      density="compact"
    >
      <VListItem
        append-icon="$account"
        class="pr-2"
      >
        <VListItemTitle class="text-subtitle-2">
          <b>{{ auth.name }}</b>
        </VListItemTitle>
      </VListItem>
      <VDivider />
      <VListItem
        v-for="(link, i) in links"
        :key="'userMenu--' + i"
        v-bind="link.props"
      >
        <VListItemTitle
          class="text-subtitle-2"
        >
          {{ link.title }}
        </VListItemTitle>
      </VListItem>
    </VList>
  </VMenu>
</template>

<style lang="scss">
.v-toolbar__nav--user {
  .v-btn__overlay {
    display: none;
  }

  &__menu {
    transform: translateX(4px);

    &, .v-list {
      border-radius: 2px !important;
    }
  }
}
</style>
