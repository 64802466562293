import photoRoutes from '../photos/routes.js'

const DealerInventory = () => import(
  /* webpackChunkName: "inventory" */
  './Manager/index.vue'
)

export default [
  {
    component: DealerInventory,
    meta: { title: 'Inventory' },
    name: 'inventory',
    path: '/inventory'
  },
  ...photoRoutes({
    name: 'inventory.vehicle.photos.embed',
    path: '/inventory/:id/photos/embed',
    meta: { layout: 'none' },
    defaultTab: 'inventory'
  }),
  {
    component: () => import(
      /* webpackChunkName: "inventory" */
      './VehicleDetails/index.vue'
    ),
    name: 'inventory.vehicle',
    path: '/inventory/:id',
    meta: { fullscreen: true },
    children: [
      ...photoRoutes({
        name: 'inventory.vehicle.photos',
        path: 'photos',
        defaultTab: 'inventory',
        meta: { fullscreen: false }
      }),
      {
        name: 'inventory.vehicle.tab',
        path: ':tab(pricing|equipment|options|approval|estock)',
        meta: { fullscreen: true }
      }
    ].filter(Boolean)
  },
  {
    name: 'inventory.vehicle.transfer',
    path: '/inventory/:id/transfer',
    meta: {
      enterprise: true
    },
    component: () => import(
      /* webpackChunkName: "inventory.transfer" */
      './Transfer/index.vue'
    )
  }
]
