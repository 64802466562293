import color from 'color'
import { useTheme } from 'vuetify'

/**
 * Determine whether a color being parsed is a CSS color or a theme token.
 * @param {String} color
 * @return {Boolean}
 */
export function isCssColor (color) {
  return color && color.match(/^(#|(rgb|hsl)a?\()/)
}

/**
 * Returns a color and variant from a string. Useful when exposing a property on
 * a component which accepts a string. If a valid HTML color is provided, ex:
 * `color="#1B3564"`, this value is used without diving into the theme system.
 * Otherwise, both a token and a valid variant can be set with a single binding
 * ex: `color="primary lighten-1"`
 * @param {String} color - The binding to parse.
 * @return {String} - A hex color.
 */
export function colorFromString (color) {
  if (isCssColor(color)) return color
  let variant = 'base'
  if (color.includes(' ')) {
    [color, variant] = color.split(' ')
  }
  return getColor(color, variant)
}

export function getColor (key, variant = 'base', themeVariant = 'light') {
  const theme = useTheme()
  if (isCssColor(key)) return key
  return theme.themes[themeVariant][key]?.[variant] || key
}

export { color as parse }
