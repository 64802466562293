<script>
const isProd = process.env.DEPLOY_ENV !== 'stage'

export default {
  name: 'NetworkCheckDialog',
  data: () => ({
    categories: {
      ui: 'Frontend',
      api: 'Backend',
      partner: 'Partners'
    },
    checks: [
      { category: 'api', url: isProd ? 'api.maxapps.io' : 'api.stage.maxapps.io' },
      { category: 'api', url: isProd ? 'sso.max.firstlook.biz' : 'sso.maxstage.firstlook.biz' },
      { category: 'api', url: isProd ? 'api.firstlook.biz' : 'api.maxstage.firstlook.biz' },
      { category: 'api', url: isProd ? 'auth.firstlook.biz' : 'auth.maxstage.firstlook.biz' },
      { category: 'ui', url: 'cdn.maxapps.io/windowsticker' },
      { category: 'ui', url: 'cdn.max.auto/image/upload/placeholder' },
      { category: 'ui', url: 'embed.maxapps.io' },
      { category: 'ui', url: 'vhr.embed.maxapps.io' },
      { category: 'ui', url: isProd ? 'showroom.max.auto' : 'showroom.stage.max.auto' },
      { category: 'ui', url: isProd ? 'maxpathtopurchase.com' : 'beta.maxpathtopurchase.com' },
      { category: 'ui', url: isProd ? 'maxdigitalshowroom.com' : 'beta.maxdigitalshowroom.com' },
      { category: 'partner', url: 'googletagmanager.com' },
      { category: 'partner', url: 'google-analytics.com' },
      { category: 'partner', url: 'browser-intake-datadoghq.com' },
      { category: 'partner', url: 'snapshot.carfax.com' },
      { category: 'partner', url: 'carfax.com' },
      { category: 'partner', url: 'auth.carfax.com' },
      { category: 'partner', url: 'api.cloudinary.com/v1_1/maxdigital/image/upload' }
    ],
    loading: true
  }),
  computed: {
    checksByCategory () {
      return this.checks.reduce((res, check) => {
        res[check.category][check.url] = check
        return res
      }, { api: {}, ui: {}, partner: {} })
    },
    pass: vm => category => Object.values(vm.checksByCategory[category]).every(check => check.status === 'success'),
    failures: vm => vm.checks.filter(check => check.status !== 'success'),
    slowDuration: vm => vm.checks.filter(check => check.status === 'success' && check.performance?.duration > 500),
    average: vm => category => {
      const values = Object.values(vm.checksByCategory[category]).filter(({ performance }) => !!performance)
      return values.reduce((a, b) => a + b.performance.duration, 0) / values.length
    }
  },
  async mounted () {
    this.refresh()
  },
  methods: {
    async refresh () {
      this.loading = true
      performance.clearResourceTimings()

      await Promise.all(this.checks.map(async check => {
        this.$set(check, 'status', 'loading')

        const headers = new Headers()
        headers.append('pragma', 'no-cache')
        headers.append('cache-control', 'no-cache')

        try {
          const urlPath = `${check.url}${check.url.includes('/') ? '' : '/'}`
          const url = `https://${urlPath}?${Date.now()}`

          await fetch(url, {
            method: 'GET',
            mode: 'no-cors',
            headers
          })

          setTimeout(() => {
            this.$set(check, 'performance', performance.getEntriesByType('resource').find(perf => perf.name === url))
          }, 10)

          check.status = 'success'
        } catch (err) {
          check.status = err.message
        }
      }))

      this.loading = false
    }
  }
}
</script>

<template>
  <VCard>
    <VCardTitle class="text-h5 d-flex">
      Network check
      <VSpacer />
      <VBtn
        icon
        variant="text"
        density="comfortable"
        @click="refresh()"
      >
        <VIcon>$loading</VIcon>
      </VBtn>
      <VBtn
        icon
        variant="text"
        density="comfortable"
        @click="$emit('close', true)"
      >
        <VIcon>$close</VIcon>
      </VBtn>
    </VCardTitle>
    <VDivider />
    <VProgressLinear
      :active="loading"
      indeterminate
      color="primary"
    />
    <VContainer fluid>
      <VRow>
        <VCol
          v-for="(category, key) in categories"
          :key="key"
          cols="4"
        >
          <VAlert
            :color="loading ? 'grey' : !pass(key) ? 'error' : average(key) < 500 ? 'success' : 'warning'"
            variant="tonal"
            class="mb-4 mt-3"
            text
          >
            <div class="text-center">
              <VProgressCircular
                v-if="loading"
                indeterminate
                size="36"
              />
              <VIcon
                v-else
                :color="!pass(key) ? 'error' : average(key) < 500 ? 'success' : 'warning'"
                size="large"
                :icon="!pass(key) ? '$error' : average(key) < 500 ? '$success' : '$warning'"
              />
              <h3 class="mt-2">
                {{ category }}
              </h3>
              <VSpacer />
              {{ $filters.number(average(key)) }}ms
            </div>
          </VAlert>
        </VCol>
      </VRow>
    </VContainer>
    <VCardText v-if="!loading && (failures.length || slowDuration.length)">
      <h4 class="text-grey-darken-2 text-center">
        Issues Detected
      </h4>
      <p
        v-for="failed in failures"
        :key="failed.url"
        class="text-body-2 my-2 text-center"
      >
        {{ failed.url }}<br>
        <span class="text-error">({{ failed.status }})</span>
      </p>
      <p
        v-for="slow in slowDuration"
        :key="slow.url"
        class="text-body-2 my-2 text-center"
      >
        {{ slow.url }}<br>
        <span class="text-warning">Slow Response: {{ $filters.number(slow.performance.duration) }}ms</span>
      </p>
    </VCardText>
  </VCard>
</template>
