import { defineComponent, h, ref } from 'vue'
import { VDialog } from 'vuetify/components'

/**
 * @typedef {import('vue').VNode} VNode
 */

/**
 * A map of registered dialogs. In an ideal scenario, there should be only one dialog at a time.
 * But in case of multiple dialogs, this map will help to manage them.
 * @type {Record<String, VNode>}
 */
export const registeredDialogs = ref({})

/**
 * Register a dialog component to the dialogs map
 * @param {VNode} Component Vue component of the dialog
 * @param {Record<string, any>} [props={}] Properties to be passed to the component to be used as the dialog's content
 * @return {String} UUID of the registered dialog
 * @example
 * const uuid = registerDialog(MyComponent, { prop1: 'value1' })
 * unregisterDialog(uuid)
 */
const registerDialog = (Component, props = {}) => {
  const uuid = crypto.randomUUID()
  registeredDialogs.value[uuid] = h(Component, { componentProps: props })
  return uuid
}

/**
 * Unregister a dialog component from the dialogs map
 * @param {String} uuid UUID of the dialog to be unregistered
 */
const unregisterDialog = uuid => {
  delete registeredDialogs.value[uuid]
}

/**
 * @typedef {Object} DialogAPI
 * @property {(options?: Record<string, any>) => Promise<any>} open Open the dialog passing options as properties to the dialog content's component
 * @property {(response?: any) => Promise<void>} close Close the dialog with a given response
 * @property {(options?: { open?: Record<string, any>, close?: any }) => Promise<any>} toggle Toggle the dialog
 */

/**
 * A low level dialog component that can be used to create temporary dialogs
 * @param {VNode} WithComponent Vue component (template-based or a render method)
 * @param {Record<string, any>} [VDialogOptions={}] Vuetify VDialog API options
 * @return {DialogAPI} Dialog methods to mount and show the dialog dynamically
 */
export default (WithComponent, VDialogOptions = {}) => {
  /**
   * Current dialog UUID
   * @type {String | null}
   */
  let dialog = null

  /**
   * Get the current dialog component
   * @returns {VNode | null} Vue component of the current dialog
   */
  const getCurrentDialog = () => registeredDialogs.value[dialog]

  /**
   * Unregister the current dialog from the dialogs map
   * @returns {void}
   */
  const unregisterCurrentDialog = () => {
    if (!dialog) return
    unregisterDialog(dialog)
    dialog = null
  }

  /**
   * Create a programmatic dialog component
   * @param {Function} [cb] A callback function to be called when the dialog is closed (normally a promise's resolve function)
   * @returns {VNode} Vue component of the programmatic dialog
   */
  const makeProgrammaticDialog = cb => {
    const ProgrammaticDialog = defineComponent({
      name: 'ProgrammaticDialog',
      props: {
        componentProps: {
          type: Object,
          default: () => ({})
        }
      },
      setup (props, { expose }) {
        const showDialog = ref(true)

        const close = response => {
          showDialog.value = false
          cb?.(response)
          return new Promise(resolve => setTimeout(() => resolve(unregisterCurrentDialog()), 500))
        }

        expose({ close })

        return () => h(VDialog, {
          'onUpdate:modelValue': () => close(false),
          modelValue: showDialog.value,
          width: 500,
          ...VDialogOptions
        }, [
          h(WithComponent, {
            onClose: $event => close($event),
            ...props.componentProps
          })
        ])
      }
    })

    return ProgrammaticDialog
  }

  const dialogAPI = {
    /**
     * Open the dialog
     * @param {Record<string, any>} [options] Options to be passed as properties to the dialog content's component
     * @returns {Promise<any>} A promise that resolves with the response when the dialog is closed
     */
    async open (options) {
      if (dialog) {
        await dialogAPI.close()
      }

      const response = await new Promise(resolve => {
        dialog = registerDialog(makeProgrammaticDialog(resolve), options)
      })

      return response
    },

    /**
     * Close the dialog
     * @param {any} [response=false] Response to be passed to the dialog's close callback
     * @returns {Promise<void>} A promise that resolves when the dialog is closed
     */
    close (response = false) {
      const currentDialog = getCurrentDialog()

      if (!currentDialog?.component) return Promise.resolve()
      return currentDialog.component.exposed.close(response)
    },

    /**
     * Toggle the dialog
     * @param {{ open?: Record<string, any>, close?: any }} [options]
     * @property {Record<string, any>} [options.open] Options to be passed as properties to the dialog content's component when opening the dialog
     * @property {any} [options.close=false] Response to be passed to the dialog's close callback when closing the dialog
     * @returns {Promise<any>} A promise that resolves with the response when the dialog is closed
     */
    toggle (options) {
      if (!dialog) return dialogAPI.open(options?.open)

      return dialogAPI.close(options?.close)
    }
  }

  return dialogAPI
}
