<script>
import features from '~/plugins/features'

export default {
  name: 'FeaturePreviewDialog',
  data: () => ({
    features: features.available,
    overrides: features.overrides
  }),
  computed: {
    available () {
      return Object.keys(this.features)
    },
    enabled: {
      get () {
        return this.available.reduce((result, feature, i) => {
          if (features.enabled.has(feature)) {
            result.push(i)
          }
          return result
        }, [])
      },
      set (newValue) {
        features.enabled = newValue.map(index => this.available[index])
      }
    }
  }
}
</script>

<template>
  <VCard>
    <VCardTitle class="d-flex text-h5">
      Feature preview
      <VSpacer />
      <VBtn
        icon
        variant="text"
        density="comfortable"
        @click="$emit('close', true)"
      >
        <VIcon>$close</VIcon>
      </VBtn>
    </VCardTitle>
    <VDivider />
    <VList
      variant="flat"
      lines="two"
    >
      <VListItem
        v-for="(feature, key) in features"
        :key="key"
        class="pl-6 pr-7"
      >
        <template #default>
          <VListItemTitle>{{ feature.title }}</VListItemTitle>
          <VListItemSubtitle>
            {{ feature.subtitle }}
          </VListItemSubtitle>
        </template>
        <template #append="{ active }">
          <VListItemAction>
            <VSwitch
              :model-value="active"
              :disabled="overrides.includes(key)"
              hide-details
              inset
            />
          </VListItemAction>
        </template>
      </VListItem>
    </VList>
  </VCard>
</template>
