import menuDefinition from './menu-definition.js'
import {
  getFirstlookUrl,
  getFirstlookReportsUrl,
  getlilypadUrl,
  hasFeature,
  isIncluded
} from './menu-utils.js'

/**
 * This module exports a function that generates an array of menu items
 * based on the user's subscription level, environment and dealer configuration.
 * @module shared/menu
 * @param {Object} ctx - object containing context information
 * @param {string} ctx.buid - internal Id of the dealer
 * @param {Object} ctx.config - contains activated features for the dealer
 * @param {string} ctx.dealerGroupId - internal Id of dealer group
 * @param {string} ctx.env - target environment (e.g. dev, stage, production)
 * @param {boolean} ctx.hasGroupAccess - gives access to group related menu items
 * @param {boolean} ctx.isAppraisalOnly - indicates to use limited APPRAISAL_ONLY menu
 * @param {string} ctx.subscription - refers to which baseline menu collection is used
 * @returns {Array} menu items based on the user's subscription level and environment
 */
export default ctx => {
  const {
    buid,
    dealerGroupId,
    hasGroupAccess = false,
    env,
    config = {},
    isAppraisalOnly,
    subscription
  } = ctx

  // all non "stage" or "dev" env are considered "production"
  const envPrefix = ['stage', 'dev'].includes(env) ? env : ''

  const getMenuTree = items => items
    .filter(item => item.condition ?? true)
    .map(({ condition, ...menuItem }) => {
      if (menuItem.items) {
        menuItem.items = getMenuTree(menuItem.items)
      }

      return menuItem
    })

  return getMenuTree(
    menuDefinition({
      // Condition functions
      hasFeature: feature => hasFeature(feature, config),
      isIncluded: link => isIncluded(link, { isAppraisalOnly, subscription }),

      // Format functions
      lilypadUrl: path => getlilypadUrl(path, envPrefix),
      firstLookUrl: path => getFirstlookUrl(path, envPrefix).href,
      reportsUrl: path => getFirstlookReportsUrl(path, envPrefix).href,

      // Replaceable values
      buid,
      dealerGroupId,
      hasGroupAccess
    })
  )
}
