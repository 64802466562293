export default [
  {
    component: () => import(
      /* webpackChunkName: "settings" */
      './Settings.vue'
    ),
    path: '/settings',
    redirect: '/settings/general',
    children: [
      {
        component: () => import(
          /* webpackChunkName: "settings" */
          './General/index.vue'
        ),
        path: 'general'
      },
      {
        component: () => import(
          /* webpackChunkName: "settings" */
          './Profile/index.vue'
        ),
        path: 'profile'
      },
      {
        component: () => import(
          /* webpackChunkName: "settings" */
          './Partner.vue'
        ),
        path: 'partner'
      },
      {
        component: () => import(
          /* webpackChunkName: "settings" */
          './Inventory.vue'
        ),
        path: 'inventory'
      }
    ]
  }
]
