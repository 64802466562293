import './plugins/datadog'

import { datadogRum } from '@datadog/browser-rum'
import { createPinia } from 'pinia'
import PortalVue from 'portal-vue'
import { configureCompat, createApp } from 'vue'

import { client } from '@maxsystems/client'

import App from './App.vue'
import { name, version } from '../package.json'
import Auth from './plugins/auth'
import features from './plugins/features'
import * as icons from './plugins/icons'
import { router } from './plugins/router'
import * as versioning from './shared/version'
import filters from './ui/vue/filters'
import UI, { vuetify } from '~/ui/vue'
import vue3CompatConfig from '~/vue3.compat.config'

import '~/ui/styles/app.scss'

export const minifyTheme = css => css
  .replace(/\n\s?|\s(?=[\w!#\-{])/g, '')
  .replace(/\s{2,}/g, ' ')

configureCompat(vue3CompatConfig)

// set current release version in order to be able to determine if app was updated
// whenever we get a ChunkLoadError
versioning.init()

client.config({ name, version })

export const app = createApp({
  provide: () => ({ features }),
  router,
  created () {
    this.$auth.setup()
  },
  render: h => h(App)
})

app.use(createPinia())
app.use(vuetify)
app.use(PortalVue)
app.use(Auth)
app.use(UI, { icons })
app.use(router)
app.use(filters)

app.config.errorHandler = error => {
  if (process.env.NODE_ENV === 'production') {
    datadogRum.addError(error)
    return
  }
  console.error(error)
}

export default app.mount('#app')
// router.isReady().then(() => app.mount('#app'))
