import {
  VContainer,
  VProgressCircular
} from 'vuetify/components'

import Logo from '~/ui/logos/wordmark.vue'

export default function (h) {
  return [
    h(VContainer, {
      class: ['justify-center', 'flex-column', 'fill-height']
    }, [
      h(Logo, {
        props: {
          width: 120
        }
      }),
      h(VProgressCircular, {
        class: 'ma-4',
        props: {
          color: 'grey',
          indeterminate: true,
          size: 18,
          width: 2
        }
      }),
      h('span', {
        class: `ma-4 caption text-center text-${this.$auth.redirectTimedOut ? 'warning' : 'grey'}`,
        style: { maxWidth: '16rem', height: '3rem' }
      }, this.$auth.loadingMessage)
    ])
  ]
}
