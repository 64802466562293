<script>
import PopoverArrow from './PopoverArrow.vue'

const defaultAcvHelpdeskPhoneNumber = '8773785665'

export default {
  name: 'MenuHelp',
  components: {
    PopoverArrow
  },
  data: () => ({
    techSupportEmail: 'helpdesk@acvmax.com'
  }),
  computed: {
    accountManager: ({ $auth }) => $auth?.dealer?.accountManager?.profile,
    technicalSupport: ({ $auth }) => $auth?.dealer?.technicalSupport,
    techSupportPhone: ({ technicalSupport }) => technicalSupport?.phone.replace(/[^0-9+]/g, '') || defaultAcvHelpdeskPhoneNumber,
    techSupportName: ({ technicalSupport }) => technicalSupport?.name
  }
}
</script>

<template>
  <VBtn
    class="menu-help mr-2"
    color="white"
    variant="outlined"
    rounded="xl"
  >
    Support
    <VMenu
      activator="parent"
      location="bottom"
      content-class="v-menu__content--popover"
      :offset="[16, 4]"
      transition="slide-y-transition"
      :close-on-content-click="false"
    >
      <VSheet class="v-sheet-popover">
        <PopoverArrow />
        <div class="v-sheet-popover__contents py-4 px-3">
          <div class="text-body-2 mb-2">
            Technical Support
          </div>
          <div v-if="techSupportName">
            {{ techSupportName }}
          </div>
          <a :href="`tel:${techSupportPhone}`">
            {{ $filters.phone(techSupportPhone) }}
          </a>
          <br>
          <a :href="`mailto:${techSupportEmail}`">
            {{ techSupportEmail }}
          </a>
        </div>
        <VDivider />
        <div
          v-if="accountManager"
          class="v-sheet-popover__contents py-4 px-3"
        >
          <div class="text-body-2 mb-2">
            Training &amp; Questions
          </div>
          {{ accountManager.name }}
          <br v-if="accountManager.phone">
          <a :href="`tel:${accountManager.phone}`">
            {{ $filters.phone(accountManager.phone) }}
          </a>
          <br>
          <a :href="`mailto:${accountManager.email}`">
            {{ accountManager.email }}
          </a>
        </div>
      </VSheet>
    </VMenu>
  </VBtn>
</template>

<style lang="scss">
@use '~/ui/styles/utils';

.menu-help {
  text-transform: unset;
}

.v-menu__content--popover {
  box-shadow: unset;
  contain: unset;
  overflow: visible;

  .v-sheet-popover {
    border-radius: utils.$border-radius-root;
    font-size: 12px;
    line-height: 18px;
    width: 216px;

    a {
      color: inherit;
      opacity: 0.7;
      text-decoration: none;
    }
  }

  .popover__arrow {
    position: absolute;
    transform: rotate(180deg) translateY(13px) translateX(-32px);
  }
}
</style>
