import { h } from 'vue'

export default function Icon (props, { attrs }) {
  if (!props.src?.viewBox) return

  const [x, y, width, height] = props.src.viewBox.split(' ')

  const dimensions = { h: Number(props.height || height), w: Number(props.width || width) }

  // Scale proportionally if either width or height are provided without the
  // paired property. This should behave the same as an `<img>` tag.
  if (props.width && !props.height) {
    dimensions.h = Math.round((height / width) * props.width)
  } else if (props.height && !props.width) {
    dimensions.w = Math.round((width / height) * props.height)
  }

  return h('svg', {
    class: attrs.class,
    height: dimensions.h,
    viewBox: [x, y, width, height].join(' '),
    width: dimensions.w
  }, [h('use', { 'xlink:href': '#' + props.src.id })])
}

Icon.props = {
  height: {
    default: 0,
    type: [Number, String]
  },
  src: {
    type: Object,
    required: true
  },
  width: {
    default: 0,
    type: [Number, String]
  }
}
